export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-layout-11',
    path: '/iris/overview'
  },
  {
    name: 'Inventory Forecasting',
    icon: 'nc-icon nc-chart-bar-32',
    path: '/iris/avails'
  },
]
