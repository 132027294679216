// store.js
//import { reactive } from 'vue' // 2.6 last vue version before change to reactive
import Vue from 'vue'
import axios from 'axios'

export const store = Vue.observable({
  // [[globals]]
  propsToSearch: [],
  tableColumns: [],
  colWidthDict: false,
  ogPropsToSearch: false,
  ogTableColumns: false,
  userConfirm: false,
  irisData: false,
  reset_pass: false,
  customQuery: false,
  irisLoader: false,
  aggregateSelected: 'total',
  companySelected: 'all',
  siteSelected: 'all',
  productSelected: 'all',
  subProductSelected: 'all',
  productSubtypeSelected: '',
  segmentSelected: '',
  demoSelected: '',
  languageSelected: '',
  dmaSelected: '',
  genderSelected: '',
  ageSelected: '',
  deviceSelected: '',
  keywordsInput: '',
  exportRows: [],
  mundialData: [],
  irisText: 'Last 30 Days',
  irisDateRange: [],
  irisAvailsRange: '7',
  adminRemoveArr: ['ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  irisRemoveArr: ['inter','ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  clientRemoveArr: ['billing_entity','vertical','lang','inter','ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete','revenue'],
  irisMinMaxDate: [],
  customMinMaxDate: [],
  summarizeRows(tableRows, dimsArr, metricSet) {
    const summaryDict = {};
    let summaryArr = [],
    dailyRows = JSON.parse(JSON.stringify(tableRows)),
    dailyLen = dailyRows.length;

    for (let r = 0; r < dailyLen; r++) {

      // Create new tupleKey
      const cleanRow = dailyRows[r];
      delete cleanRow['date']

      let tmpArr = [], tupleArr = [];

      // Parse through dims & create tuples
      for (let d = 0; d < dimsArr.length; d++) {

        if (dimsArr[d] == 'date') { continue; }

        const tmpDim = dimsArr[d],
        tmpVal = cleanRow[tmpDim];

        tupleArr.push(tmpVal)
      }
      let tmpTuple = tupleArr.join('___');

      // Compile summary
      if (!summaryDict.hasOwnProperty( tmpTuple )) {
        summaryDict[tmpTuple] = summaryArr.length;
        summaryArr.push(cleanRow)
      } else {
        const tmpIdx = summaryDict[tmpTuple];
        for (const metric of metricSet.values()) {
          summaryArr[tmpIdx][metric] += cleanRow[metric]
        }
      }
    }
    return summaryArr
  },
  sanitizeMetrics(cleanRows, metricSet, summaryBool) {
    const math_round = Math.round,
    ogRows = JSON.parse(JSON.stringify(cleanRows)),
    colWidthDict = {}, colSet = new Set(Object.keys(cleanRows[0])),
    dimSet = new Set([...colSet].filter(x => !metricSet.has(x))),
    cleanLen = cleanRows.length;

    //console.log({dimSet,metricSet,store:this})

    if (cleanLen) {
      for (let c = 0; c < cleanLen; c++) {
        const tmpRow = cleanRows[c],
        ogRow = ogRows[c], metricArr = [...metricSet],
        metricLen = metricArr.length;


        //console.log({tmpRow,ogRow})


        for (let m = 0; m < metricLen; m++) {
          const metric = metricArr[m];

          switch (metric) {
            case 'adreq':
              tmpRow.adreq = ogRow.adreq ? ogRow.adreq.toLocaleString("en-US") : 0
              break;
            case 'mon_adreq':
              tmpRow.mon_adreq = ogRow.mon_adreq ? ogRow.mon_adreq.toLocaleString("en-US") : 0
              break;
            case 'imp':
              tmpRow.imp = ogRow.imp ? ogRow.imp.toLocaleString("en-US") : 0
              break;
            case 'revenue':
              tmpRow.revenue = ogRow.revenue ? `${new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(ogRow.revenue)}` : '$0.00'
              //tmpRow.revenue = ogRow.revenue ? `$${ogRow.revenue.toFixed(2)}` : '$0.00'
              break;
            case 'ivt_rate':
              if (summaryBool) {
                tmpRow.ivt_rate = ogRow.ivt_adreq ? `${((100 * ogRow.ivt_adreq) / (ogRow.adreq - ogRow.network_adreq)).toFixed(1)}%` : ''
              } else {
                tmpRow.ivt_rate = ogRow.ivt_rate ? `${ogRow.ivt_rate}%` : ''
              }
              break;
            case 'viewability':
              if (summaryBool) {
                tmpRow.viewability = ogRow.pre_view_imp ? `${(100 * ogRow.view_imp / ogRow.pre_view_imp).toFixed(1)}%` : ''
              } else {
                tmpRow.viewability = ogRow.viewability ? `${ogRow.viewability}%` : ''
              }
              break;
            case 'vcr':
              if (summaryBool) {
                tmpRow.vcr = ogRow.video_start ? `${(100 * ogRow.video_complete / ogRow.video_start).toFixed(1)}%` : ''
              } else {
                tmpRow.vcr = ogRow.vcr ? `${ogRow.vcr}%` : ''
              }
              break;
            case 'fill_rate':
              if (summaryBool) {
                tmpRow.fill_rate = `${(100 * ogRow.imp / ogRow.mon_adreq).toFixed(1)}%`
              } else {
                tmpRow.fill_rate = ogRow.fill_rate ? `${ogRow.fill_rate}%` : ''
              }
              break;
            case 'inter':
              tmpRow.inter = ogRow.inter ? 'Yes' : ''
              break;
            default:
              continue
          }

          // Populate metric widths
          const tmpLen = tmpRow[metric].length,
          titleLen = this.colsDict.hasOwnProperty(metric) ? this.colsDict[metric].length : 100;
          if (!colWidthDict.hasOwnProperty(metric)) colWidthDict[metric] = 0;
          //if (tmpLen > colWidthDict[metric] || titleLen > colWidthDict[metric]) colWidthDict[metric] = titleLen > tmpLen ? titleLen * 9 + 60 : tmpLen * 9 + 60;
          if (tmpLen > colWidthDict[metric] || titleLen > colWidthDict[metric]) colWidthDict[metric] = titleLen > tmpLen ? titleLen * 10 + 60 : tmpLen * 10 + 60;

        }

        const dimArr = [...dimSet],
        dimLen = dimArr.length;
        for (let d = 0; d < dimLen; d++) {

          // Populate dim widths
          const dim = dimArr[d], tmpLen = tmpRow[dim].length,
          titleLen = this.colsDict.hasOwnProperty(dim) ? this.colsDict[dim].length : 100;
          if (!colWidthDict.hasOwnProperty(dim)) colWidthDict[dim] = 0;
          //if (tmpLen > colWidthDict[dim] || titleLen > colWidthDict[dim]) colWidthDict[dim] = titleLen > tmpLen ? titleLen * 8 + 80 : tmpLen * 8 + 80;
          if (tmpLen > colWidthDict[dim] || titleLen > colWidthDict[dim]) colWidthDict[dim] = titleLen > tmpLen ? titleLen * 10 + 60 : tmpLen * 10 + 60;

        }

      }
    }
    this.colWidthDict = colWidthDict

    //console.log({colWidthDict})

    return cleanRows
  },
  //filterMundial([['type', 'wood'], ['size', 8], ['someother', 'value']]);
  filterMundial(filters) {
    const reformatDate = this.reformatDate, self = this,
    filterLen = filters.length;

    //console.log({filters})
    //console.log({mundial:this.irisData.mundial})

    return (this.customQuery ? this.irisData.custom : this.irisData.mundial).filter(function(val) {


      //console.log({val, filters, length:filters.length})


      let bool = true;
      for(let i = 0; i < filterLen; i++) {


        //console.log({val:val[filters[i][0]],filter:filters[i]})


        if(filters[i][0] == 'date') {
          const objCheck = filters[i][1][0] instanceof Date,
          sDate = objCheck ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][0].replaceAll('-','')),
          eDate = objCheck ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][1].replaceAll('-','')),
          tmpDate = reformatDate(val[filters[i][0]]);



          //console.log({sDate,eDate,tmpDate,objCheck})



          if (tmpDate < sDate - 1) {
            bool = false;
          }
          if (tmpDate > eDate - 1) {
            bool = false;
          }
          if (self.customQuery && tmpDate == sDate) {
            bool = true;
          }
          if (self.irisData.sessionFilters && self.customQuery && tmpDate == sDate - 1) {
            bool = true;
          }
          /*
          if (self.irisData.sessionFilters && self.irisData.adjust_custom && tmpDate < eDate - 2) {
            bool = true;
          }
          if (self.customSession && tmpDate > eDate - 1) {
            bool = false;
          }
          */
          if (objCheck || self.customQuery) {
            self.irisText = 'Custom Range';
          }
        } else if (filters[i][1].length && filters[i][1] != 'all' && val[filters[i][0]].toLowerCase() != filters[i][1]) {

          //console.log({localVal:val[filters[i][0]],localFilter:filters[i][1]})

          bool = false;
        }
      }

      return bool;

    })
  },
  // UPDATE: need to include changes from filterMundial
  filterCharts(filters) {
    const reformatDate = this.reformatDate, self = this,
    filterLen = filters.length;

    //console.log({filters})
    //console.log({mundial:this.irisData.mundial})

    return this.irisData.mundial_charts.filter(function(val) {


      //console.log({val, filters, length:filters.length})


      let bool = true;
      for(let i = 0; i < filterLen; i++) {


        //console.log({val:val[filters[i][0]],filter:filters[i]})


        if(filters[i][0] == 'date') {
          const objCheck = filters[i][1][0] instanceof Date,
          sDate = objCheck ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][0].replaceAll('-','')),
          eDate = objCheck ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][1].replaceAll('-','')),
          tmpDate = reformatDate(val[filters[i][0]]);



          //console.log({sDate,eDate,tmpDate})



          if (tmpDate < sDate - 1) {
            bool = false;
          }
          if (tmpDate > eDate - 1) {
            bool = false;
          }
          if (objCheck) {
            self.irisText = 'Custom Range';
          }
        } else if (filters[i][1].length && filters[i][1] != 'all' && val[filters[i][0]].toLowerCase() != filters[i][1]) {

          //console.log({localVal:val[filters[i][0]],localFilter:filters[i][1]})

          bool = false;
        }
      }

      return bool;

    })
  },
  reformatDate(oldDate) {
    return parseInt([oldDate.split('-')[2],oldDate.split('-')[0],oldDate.split('-')[1]].join(''))
  },
  irisDate() {
        Date.prototype.GetFirstDayOfWeek = function() {
            return (new Date(this.setDate(this.getDate() - this.getDay()+ (this.getDay() == 0 ? -6 : 1) )));
        }
        Date.prototype.GetLastDayOfWeek = function() {
            return (new Date(this.setDate(this.getDate() - this.getDay() + 7)));
        }
        Date.prototype.GetLastSeven = function() {
            const startDay = new Date(),
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 7);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }
        Date.prototype.GetLastThirty = function() {
            const startDay = new Date(),
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 30);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }
        Date.prototype.GetThisWeek = function() {
            const startDay = this.GetFirstDayOfWeek(),
              endDay = this.GetLastDayOfWeek();
            return ({startDay, endDay});
        }
        Date.prototype.GetThisMonth = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth(), 1),
              endDay = new Date(this.getFullYear(), this.getMonth() + 1, 0);
            return ({ startDay, endDay });
        }
        Date.prototype.GetLastWeek = function() {
            const startDay = this.GetFirstDayOfWeek(),
              endDay = this.GetLastDayOfWeek();
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 7);
            endDay.setTime(endDay.getTime() - 3600 * 1000 * 24 * 7);
            return ({startDay, endDay});
        }
        Date.prototype.GetLastMonth = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth() - 1, 1),
              endDay = new Date(this.getFullYear(), this.getMonth(), 0);
            startDay.setTime(startDay.getTime() + 3600 * 1000 * 24);
            endDay.setTime(endDay.getTime() + 3600 * 1000 * 24);
            return ({startDay, endDay});
        }
        return new Date()
  },
  clearIrisTimeout() {
        clearTimeout(this.session_timeout)
  },
  irisTimeout() {
        const minutes = 90;
        //const minutes = 0.5;
        this.session_timeout = setTimeout(() => { this.confirmSession() }, 1000 * 60 * minutes)
  },
  async logoutIris() {
        const LogOut = await axios.get('logout/'),
        boolCheck = typeof LogOut.data.logout !== "undefined" ? LogOut.data.logout : 'expired';

        //console.log({boolCheck, store:this, session_router:this.session_router})

        // MUST match [[globals]]
        if (boolCheck) {
            this.propsToSearch = []
            this.tableColumns = []
            this.colWidthDict = false
            this.ogPropsToSearch = false
            this.ogTableColumns = false
            this.userConfirm = false
            this.irisData = false
            this.reset_pass = false
            this.customQuery = false
            this.irisLoader = false
            this.aggregateSelected = 'total'
            this.companySelected = 'all'
            this.siteSelected = 'all'
            this.productSelected = 'all'
            this.subProductSelected = 'all'
            this.productSubtypeSelected = ''
            this.segmentSelected = ''
            this.demoSelected = ''
            this.languageSelected = ''
            this.dmaSelected = ''
            this.genderSelected = ''
            this.ageSelected = ''
            this.deviceSelected = ''
            this.keywordsInput = ''
            this.exportRows = []
            this.mundialData = []
            this.irisText = 'Last 30 Days'
            this.irisDateRange = []
            this.irisMinMaxDate = []
            this.customMinMaxDate = []
            this.irisAvailsRange = '7'
        }

        // Redirect Login (expired sessions)
        if(boolCheck === 'expired'){
            this.session_router.push({'name':'Login'})
            this.session_router = false
            this.clearIrisTimeout()
        }
  },
  async confirmTC(tcForm) {
        // POST Confirm Terms of Use
        const UpdateData = await axios.post('confirmterms/',tcForm),
        boolCheck = UpdateData.data.user;

        if (boolCheck) {
          this.irisData.user = boolCheck
        }
  },
  async valid_reset_pass() {
        // POST Confirm Terms of Use
        const email = this.reset_pass ? this.reset_pass.email : false,
        password = this.reset_pass ? this.reset_pass.password : false,
        ValidResetPass = email && password ? await axios.post('validreset/',{ email, password }) : false,
        boolCheck = ValidResetPass ? ValidResetPass.data.valid_reset : ValidResetPass;

        //console.log({ValidResetPass, boolCheck})

        return boolCheck
  },
  async resetSessionFilters(sessionForm) {
        // POST add filters to Django session
        const UserSession = await axios.post('sessionfilters/',sessionForm), InvalidSess = UserSession.data.invalid_session;

        // Alert session errors
        if (InvalidSess) {
          alert('Session invalid! Please notify Mundial Media admins.')
        }
  },
  async resetIrisPass(resetPassForm) {
        //console.log('_____')
	//console.log('')
        //console.log({resetPassForm})
	//console.log('')
        //console.log('_____')

        // POST InvalidPass
        const ValidatePass = await axios.post('resetpass/',resetPassForm), InvalidPass = ValidatePass.data.invalid_pass;
        this.irisData = InvalidPass

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (InvalidPass) {
          alert(`Please check your email inbox for ${resetPassForm.email}!`)
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
  },
  async setNewPass(setPassForm) {
        //console.log('_____')
	//console.log('')
        //console.log({setPassForm})
	//console.log('')
        //console.log('_____')

        // POST InvalidPass
        const ValidatePass = await axios.post('setnewpass/',setPassForm), InvalidPass = ValidatePass.data.invalid_pass;
        this.irisData = InvalidPass

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (!InvalidPass) {
          this.reset_pass = false
          alert('Password set successfully!')
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
  },
  async confirmSession() {
        // GET Confirm Session
        const SessionData = await axios.get('confirmsession/'),
        boolCheck = typeof SessionData.data.status !== 'undefined' ? SessionData.data.status : false;

        //console.log({boolCheck})

        // Logout Iris (if expired)
        if (!boolCheck) {
          this.logoutIris()
        } else {
          this.clearIrisTimeout()
          this.irisTimeout()
        }
  },
  async loginIris(loginForm) {
        if (loginForm.password) this.irisLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const UserData = await axios.post('login/',loginForm)

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        this.irisData = UserData.data.user ? UserData.data : false
        if (this.irisData) this.irisTimeout()

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.irisLoader = false
  },
  async initMundial() {
        this.irisLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const MundialData = await axios.get('initdata/'),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (boolCheck) this.irisData.mundial = boolCheck

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.irisLoader = false
  },
  async createMundial(mForm) {
        this.irisLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const ProfileData = await axios.post('createmundial/',mForm),
        boolCheck = ProfileData.data.user;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.irisLoader = false
        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            //this.irisData.user = boolCheck
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async updateIris(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('irisupdate/',uForm),
        boolCheck = UpdateData.data.user;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.irisData.user = boolCheck
            return true
        } else {
            return false
        }
  },
  async irisAvails(aForm) {
        //console.log('_____')
	//console.log('')
        //console.log({aForm})
	//console.log('')
        //console.log('_____')

        // POST AvailsData
        const AvailsData = await axios.post('irisavails/',aForm),
        boolCheck = AvailsData.data.avails;

        // Return avails
        //console.log('_ _ _ _ _ _')
        //console.log({AvailsData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        return boolCheck
  },
  async customMundial() {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const sdate = this.customMinMaxDate[0].toISOString().split('T')[0], edate = this.customMinMaxDate[1].toISOString().split('T')[0],
        date_range = [sdate, edate],
        customData = await axios.post('customdata/',{ date_range }),
        boolCheck = customData.data.custom;

        // Return Data

        //console.log('_ _ _ _ _ _')
        //console.log({customData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            return boolCheck
        } else {
            return this.irisData.mundial
        }
  },
  async sessionLogin(session_router) {
        await this.loginIris({email:'sessionid', password:false})

        //console.log({store, session:true})

        // Redirect
        if(store.irisData){
          session_router.push({'name':'Overview'})
        }
  },
  download(data) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: 'text/csv' });
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    const ahoraMismo = new Date();
    a.setAttribute('download', `iris_report_${this.irisText.toLowerCase().replace(' ','_')}__${ahoraMismo.toISOString()}.csv`);
    // Performing a download with click
    a.click()
  },
  csvmaker(exportArr) {
    // Empty array for storing the values
    let csvRows = [], headers = Object.keys(exportArr[0]),
    dataArr = JSON.parse(JSON.stringify(exportArr)),
    dataLen = dataArr.length;
    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    const adminRemoveArr = this.adminRemoveArr, adminRemoveSet = new Set(adminRemoveArr),
    clientRemoveArr = this.clientRemoveArr, clientRemoveSet = new Set(clientRemoveArr),
    irisRemoveArr = this.irisRemoveArr, irisRemoveSet = new Set(irisRemoveArr),
    is_staff = this.irisData.user.is_staff,
    is_admin = this.irisData.user.is_superuser;
    if(is_staff && !is_admin) {
       headers = headers.filter(function (item) {
          return !irisRemoveSet.has(item);
       });
    } else if (!is_admin) {
       headers = headers.filter(function (item) {
          return !clientRemoveSet.has(item);
       });
    } else {
       headers = headers.filter(function (item) {
          return !adminRemoveSet.has(item);
       });
    }
    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    const cleanHeaders = [],
    colsDict = this.colsDict,
    headLen = headers.length;
    for (let h = 0; h < headLen; h++) {
      const cleanHead = colsDict[headers[h]];
      cleanHeaders.push(cleanHead)
    }
    csvRows.push(cleanHeaders.join(','));
    // Pushing Object values into array
    // with comma separation
    for(let d = 0; d < dataLen; d++) {
       let dataRow = dataArr[d];
       if(is_staff && !is_admin) {
          irisRemoveArr.forEach(e => delete dataRow[e])
       } else if (!is_admin) {
          clientRemoveArr.forEach(e => delete dataRow[e])
       } else {
          adminRemoveArr.forEach(e => delete dataRow[e])
       }

       // TEST (maintained for testing purposes)
       //console.log({dataRow})

       // Add quotes to preserve commas during CSV export
       for(let h = 0; h < headLen; h++) {
         const tmpHeader = headers[h];
         dataRow[tmpHeader] = `\"${dataRow[tmpHeader]}\"`
       }

       //console.log({dataRow})

       const val = Object.values(dataRow).join(',');
       csvRows.push(val)
    }
    // Returning the array joining with new line
    return csvRows.join('\n')
  },
  queriedData(searchQuery) {
        if (!searchQuery.length) {
          //return this.irisData.mundial
          //return this.mundialData
          return this.exportRows
        }
        const propsToSearch = this.propsToSearch,
        lowerSearchQuery = searchQuery.toLowerCase(),
        propLen = propsToSearch.length;
        //let result = this.irisData.mundial
        //let result = this.mundialData
        let result = this.exportRows
          .filter((row) => {
            let isIncluded = false


            // TEMP
            //console.log({row})



            for(let k = 0; k < propLen; k++) {
              const tmpKey = propsToSearch[k];

              // TEST (maintained for testing purposes)
              //console.log({key})
              //console.log(row[key])
              //console.log(row[key].toLowerCase())

              let rowValue = row[tmpKey].toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        return result
  },
  async exportIris(searchQuery) {
        this.irisLoader = true

        const csvData = this.queriedData(searchQuery), csvFile = this.csvmaker(csvData);
        await this.download(csvFile);

        this.irisLoader = false
  },
})
