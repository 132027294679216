<template>
  <div class="wrapper">

    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks">
      <user-menu></user-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>


        <!-- cookie policy modal -->
        <modal :show.sync="modals.cookiePolicy"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Cookie Policy</h3>
          <cookie-policy class="iris-modal"></cookie-policy>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.cookiePolicy = false">Close</p-button>
          </div>

        </modal>


        <!-- privacy notice modal -->
        <modal :show.sync="modals.privacyNotice"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Privacy Notice</h3>
          <privacy-notice class="iris-modal"></privacy-notice>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.privacyNotice = false">Close</p-button>
          </div>

        </modal>


        <!-- terms conditions modal -->
        <modal :show.sync="modals.termsConditions"
                 v-if="store.irisData ? store.irisData.user.display_tc : store.irisData"
                 class="iris-terms"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Terms & Conditions</h3>
          <terms-conditions :toggleUserConfirmation="toggleUserConfirmation" :userConfirm="userConfirm" class="iris-modal"></terms-conditions>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" v-if="!userConfirm" disabled round>Agree</p-button>
                 <p-button type="info" v-else round @click.native="confirmIrisTerms">Agree</p-button>
          </div>

        </modal>
        <modal :show.sync="modals.termsConditions"
                 v-else
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Terms & Conditions</h3>
          <terms-conditions :toggleUserConfirmation="toggleUserConfirmation" :userConfirm="userConfirm" class="iris-modal"></terms-conditions>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.termsConditions = false">Close</p-button>
          </div>

        </modal>

        <!-- loading modal -->
        <modal :show.sync="store.irisLoader"
                 footerClasses="justify-content-center"
                 class="iris-terms iris-loader-modal"
                 type="notice">
          <h3 slot="header" class="modal-title"><iris-loader-text></iris-loader-text></h3>
          <iris-loader></iris-loader>
          <h3 slot="footer" class="modal-title">Mundial Media</h3>
        </modal>

      <content-footer :toggleCookie="toggleCookie"
                      :togglePrivacy="togglePrivacy"
                      :toggleTerms="toggleTerms"></content-footer>


    </div>
  </div>
</template>
<script>
  import { Button, Modal } from 'src/components/UIComponents';
  import TopNavbar from './TopNavbar.vue'
  import CookiePolicy from 'src/components/Dashboard/CookiePolicy'
  import PrivacyNotice from 'src/components/Dashboard/PrivacyNotice'
  import TermsConditions from 'src/components/Dashboard/TermsConditions'
  import IrisLoader from 'src/components/Dashboard/IrisLoader'
  import IrisLoaderText from 'src/components/Dashboard/IrisLoaderText'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import { store } from 'src/store'

  export default {
    components: {
      CookiePolicy,
      PrivacyNotice,
      TermsConditions,
      IrisLoader,
      IrisLoaderText,
      TopNavbar,
      ContentFooter,
      DashboardContent,
      UserMenu,
      Modal,
      [Button.name]: Button
    },
    methods: {
      async confirmIrisTerms() {
          const tcForm = {};
          tcForm.user_id = this.store.irisData.user.id
          tcForm.confirmed = true

          await this.store.confirmTC(tcForm)
          this.modals.termsConditions = false

          if (this.$route.path !== "/iris/overview") { this.$router.push({'name':'Overview'}) }
      },
      toggleUserConfirmation(bool) {
        this.userConfirm = bool
      },
      toggleCookie() {
        this.modals.cookiePolicy = !this.modals.cookiePolicy
      },
      togglePrivacy() {
        this.modals.privacyNotice = !this.modals.privacyNotice
      },
      toggleTerms() {
        this.modals.termsConditions = !this.modals.termsConditions
      },
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
    data() {
      return {
        store,
        userConfirm: false,
        modals: {
          cookiePolicy: false,
          privacyNotice: false,
          termsConditions: false
        }
      }
    },
    mounted () {
      if (this.store.irisData.user.display_tc) {
        this.modals.termsConditions = true
      } else {
        this.userConfirm = true
      }
    },
    beforeDestroy() {
      this.modals.cookiePolicy = false
      this.modals.privacyNotice = false
      this.modals.termsConditions = false
    }
  }

</script>
<style>
.iris-terms {
  pointer-events: none !important;
}
.iris-terms button.close {
  display: none !important;
}
</style>
