<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://www.mundialmedia.com" target="_blank" rel="noopener">Mundial Media</a>
          </li>


          <!-- TEMP, working correctly
          <li>
            <a class="iris-a" @click="toggleCookie()">Cookie Policy</a>
          </li>
          END -->


          <li>
            <a class="iris-a" @click="togglePrivacy()">Privacy Notice</a>
          </li>
          <li>
            <a class="iris-a" @click="toggleTerms()">Terms & Conditions</a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; Mundial Media - Iris ver {{ IRIS_VERSION }}
      </div>

      <!-- OG text
      <div class="copyright pull-right">
        &copy; Coded with
        <i class="fa fa-heart heart"></i> by <a href="https://www.mundialmedia.com" target="_blank" rel="noopener">Mundial Media</a>.</span>
      </div>
      END -->

    </div>
  </footer>
</template>
<script>
  export default {
    props: ['toggleCookie','togglePrivacy','toggleTerms'],
    data () {
      return {
        IRIS_VERSION,
      }
    }
  }
</script>
<style>
.iris-a {
  cursor: pointer;
}
</style>
