<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login" v-if="loginReset">
                <card type="login">
                  <h3 slot="header" class="header text-center">Iris Login</h3>

                  <ValidationProvider
                    name="email"
                    rules="required|email|min:5"
                    v-slot="{ passed, failed }"
                  >              
                    <fg-input v-model="form.email" addon-left-icon="nc-icon nc-single-02"
                      class="iris-login-field"
                      placeholder="Email..."
                      :error="failed ? 'The Email field is required': null"
                      :hasSuccess="passed"
                      name="email"
                    ></fg-input>
                  </ValidationProvider>

                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >              
                    <fg-input v-model="form.password" addon-left-icon="nc-icon nc-key-25" placeholder="Password..."
                      class="iris-login-field"
                      type="password"
                      :error="failed ? 'The Password field is required': null"
                      :hasSuccess="passed"
                      name="password"
                    ></fg-input>
                  </ValidationProvider>

                  <a class="iris-a" @click="toggleLogin()">Reset Password</a>

                  <br>
		
                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3">Enter</p-button>
                </card>
              </form>
              <form @submit.prevent="resetPass" v-else>
                <card type="login">
                  <h3 slot="header" class="header text-center">Reset Password</h3>

                  <ValidationProvider
                    name="email"
                    rules="required|email|min:5"
                    v-slot="{ passed, failed }"
                  >              
                    <fg-input v-model="form.email" addon-left-icon="nc-icon nc-single-02"
                      class="iris-login-field"
                      placeholder="Email..."
                      :error="failed ? 'Email field is required': null"
                      :hasSuccess="passed"
                      name="email"
                    ></fg-input>
                  </ValidationProvider>
		
                  <p-checkbox v-model="sendEmail">
                    I understand my password is being reset.
                  </p-checkbox>

                  <!-- TEMP
                  <p-checkbox @click.native="toggleUserConfirmation(!userConfirm)" v-model="userConfirm" v-if="store.irisData ? !userConfirm : store.irisData">I have read and understand these Terms of Use.</p-checkbox>
                  <p-checkbox v-else disabled :checked="true">I have read and understand these Terms of Use.</p-checkbox>
                  -->

                  <br>

                  <a class="iris-a" @click="toggleLogin()">Return to Login</a>

                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3" v-if="form.email.length && sendEmail">Send Email</p-button>
                  <p-button slot="footer" type="warning" round block class="mb-3" disabled v-else>Send Email</p-button>

                </card>
              </form>
            </div>
          </div>
        </div>


        <!-- cookie policy modal -->
        <modal :show.sync="modals.cookiePolicy"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Cookie Policy</h3>
          <cookie-policy class="iris-modal"></cookie-policy>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.cookiePolicy = false">Close</p-button>
          </div>
        
        </modal>


        <!-- privacy notice modal -->
        <modal :show.sync="modals.privacyNotice"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Privacy Notice</h3>
          <privacy-notice class="iris-modal"></privacy-notice>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.privacyNotice = false">Close</p-button>
          </div>
        
        </modal>


        <!-- terms conditions modal -->
        <modal :show.sync="modals.termsConditions"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Terms & Conditions</h3>
          <terms-conditions class="iris-modal"></terms-conditions>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.termsConditions = false">Close</p-button>
          </div>
        
        </modal>


        <!-- loading modal -->
        <modal :show.sync="store.irisLoader"
                 footerClasses="justify-content-center"
                 class="iris-terms iris-loader-modal"
                 type="notice">
          <h3 slot="header" class="modal-title"><iris-loader-text></iris-loader-text></h3>
          <iris-loader></iris-loader>
          <h3 slot="footer" class="modal-title">Mundial Media</h3>
        </modal>


        <app-footer :toggleCookie="toggleCookie"
                    :togglePrivacy="togglePrivacy"
                    :toggleTerms="toggleTerms"></app-footer>


        <div class="full-page-background" style="background-image: url(static/img/background/iris_bkg.webp) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button, Modal } from 'src/components/UIComponents';
  import CookiePolicy from 'src/components/Dashboard/CookiePolicy'
  import PrivacyNotice from 'src/components/Dashboard/PrivacyNotice'
  import TermsConditions from 'src/components/Dashboard/TermsConditions'
  import IrisLoader from 'src/components/Dashboard/IrisLoader'
  import IrisLoaderText from 'src/components/Dashboard/IrisLoaderText'
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import axios from 'axios'
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);

  export default {
    components: {
      CookiePolicy,
      PrivacyNotice,
      TermsConditions,
      IrisLoader,
      IrisLoaderText,
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      Modal
    },
    methods: {
      toggleLogin() {
        this.loginReset = !this.loginReset
      },
      toggleCookie() {
        this.modals.cookiePolicy = !this.modals.cookiePolicy
      },
      togglePrivacy() {
        this.modals.privacyNotice = !this.modals.privacyNotice
      },
      toggleTerms() {
        this.modals.termsConditions = !this.modals.termsConditions
      },
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      async resetPass() {
        // handle login here
	const self = this;

	// GET User & Update State
	let params = Object.assign({}, self.form)
	await store.resetIrisPass(params)

        // Redirect
        this.sendEmail = false
        this.loginReset = true
      },
      async login() {
        // handle login here
	const self = this;

        // Kept for reference for Forms
	//let UserForm = new FormData();
	//UserForm.append('email',self.form.email)
	//UserForm.append('password',self.form.password)

	// GET User & Update State
	let params = Object.assign({}, self.form)
	await store.loginIris(params)

        // Redirect
        if(store.irisData){
          this.$router.push({'name':'Overview'})
        } else {
          alert('EMAIL or PASSWORD is incorrect!')
        }
      },
      async sessionLogin() {
        await store.loginIris({email:'sessionid', password:false})

        //console.log({store, session:true})

        // Redirect
        if(store.irisData){
          this.$router.push({'name':'Overview'})
        }
      }
    },
    data() {
      return {
	store,
        sendEmail: false,
        loginReset: true,
        modals: {
          cookiePolicy: false,
          privacyNotice: false,
          termsConditions: false
        },
        form: {
          email: '',
          password: ''
        }
      }
    },
    async beforeCreate() {
      if (store.reset_pass) {
        store.irisData = true
        await this.$router.push({'name':store.reset_pass.name})
      }

      // Check for existing session
      await store.sessionLogin(this.$router)
    },
    beforeDestroy() {
      this.closeMenu()
      this.modals.cookiePolicy = false
      this.modals.privacyNotice = false
      this.modals.termsConditions = false
    }
  }
</script>
<style>
.iris-modal {
  max-height: 48vh;
  overflow-y: auto;
  margin-right: -1.5em;
  margin-left: -1em;
  zoom: 72%;
}
.iris-login-field {
  margin-bottom: 10px !important;
}
</style>
